<script setup lang="ts">
import { computed, ref } from '#imports'
import { useElementSize } from '@vueuse/core'
import showdown from 'showdown'
import Button from './Button.vue'

const props = defineProps<{
  content?: string
  loading?: boolean
  title?: string
  transparent?: boolean
  custom?: boolean
  viewMoreFeature?: boolean
}>()

const CHAR_MAX = 325

const contentEl = ref(null as HTMLElement | null)

const isHighEnough = ref(props.content && props.content.length > CHAR_MAX)

const showFullHeight = ref(false)
const toggleViewMore = () => {
  showFullHeight.value = !showFullHeight.value
}

const getHtml = (content: string) => {
  const converter = new showdown.Converter()
  const output = converter.makeHtml(content)
  return output
}

const data = computed(() => getHtml(props.content || ''))
</script>

<template>
  <div :class="{ 'bg-neutral-50': !transparent, 'rounded-lg p-5': !custom }">
    <div>
      <slot name="header" />
      <p
        v-if="title"
        class="subtitle-2 mb-2"
        :class="[transparent ? 'text-primary-900' : 'text-neutral-900']"
      >
        {{ title }}
      </p>
      <div
        ref="contentEl"
        class="prose max-w-full"
        :class="{
          'body-2 text-neutral-900': !custom,
          'line-clamp-5': !showFullHeight && viewMoreFeature && isHighEnough,
        }"
        v-html="data"
      />
      <Button
        v-if="viewMoreFeature && !showFullHeight && isHighEnough"
        class="mt-2"
        size="xs"
        variant="text"
        @click="toggleViewMore"
      >
        View more
      </Button>
      <Button
        v-if="viewMoreFeature && showFullHeight && isHighEnough"
        size="xs"
        variant="text"
        @click="toggleViewMore"
      >
        View less
      </Button>
      <div v-if="loading" class="animate-pulse">
        <div class="mb-1 h-2 rounded bg-slate-200" />
        <div class="mb-1 h-2 rounded bg-slate-200" />
        <div class="mb-1 h-2 rounded bg-slate-200" />
        <div class="mb-1 h-2 rounded bg-slate-200" />
      </div>
    </div>
    <slot name="footer" />
  </div>
</template>
