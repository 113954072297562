<script setup generic="T" lang="ts">
import { computed } from '#imports'
import type { Api } from '@rialtic/api'
import type { SortState } from '../composables/table'
import { formatDisplayDate } from '../composables/date'
import { formatCents } from '../composables/useNumeral'
import { influencingClaimHeaders } from '../constants'
import RefDataCode from './RefDataCode.vue'
import DataTable from './DataTable.vue'

const props = defineProps<{
  influencingClaims: T[]
  claim: Api.ProcessedClaimWithInsights
  hasFirstDataLoaded?: boolean
  hideEndMessage?: boolean
  lineNumber: string
  loading?: boolean
  sortOptions: SortState<T>
  sticky?: boolean
}>()

const emit = defineEmits<{
  'click:claimline': [item: T]
  'sort:state': [state: SortState<T>]
}>()

const claimLine = computed(
  () =>
    props.claim?.claimLines.find(
      ({ lineNumber: num }) => `${num}` === props.lineNumber,
    ),
)
</script>

<template>
  <DataTable
    v-bind="{ headers: influencingClaimHeaders, data: influencingClaims }"
    class="pb-6"
    :has-first-data-loaded="hasFirstDataLoaded"
    :hide-end-message="hideEndMessage"
    :loading
    :sort-options="sortOptions"
    :sticky
    :sticky-offset="64"
    @click:item="(item) => emit('click:claimline', item)"
    @sort="(state) => emit('sort:state', state)"
  >
    <template #prepend>
      <tr v-if="claim" class="bg-secondary-50 group">
        <td class="py-2 pl-4 lg:pl-8">
          <span
            class="bg-secondary-100 text-secondary-900 subtitle-2 whitespace-nowrap rounded-md px-3 py-1 text-center capitalize"
          >
            Under Evaluation
          </span>
        </td>
        <td class="font-fixed truncate p-3 text-sm font-semibold">
          {{ claim.id }}
        </td>
        <td
          class="table-numbers-1 py-3 pr-9 text-right uppercase text-neutral-900"
        >
          {{ lineNumber }}
        </td>
        <td class="table-cell">
          {{
            formatDisplayDate(
              claimLine?.servicedPeriod.start || undefined,
              'MM/dd/yyyy',
            ) || '–'
          }}
        </td>

        <td class="table-cell">
          {{
            formatDisplayDate(
              claimLine?.servicedPeriod.end || undefined,
              'MM/dd/yyyy',
            ) || '–'
          }}
        </td>

        <td class="table-cell">
          <RefDataCode
            v-if="claimLine?.productOrService?.coding?.[0]?.code"
            :code="claimLine?.productOrService?.coding?.[0]?.code"
            model="Procedure"
          />
          <span v-else>–</span>
        </td>

        <td class="table-cell whitespace-nowrap text-right">
          {{ claimLine?.quantity?.value }}
          {{ claimLine?.quantity?.unit }}
        </td>

        <td class="table-cell">
          <RefDataCode
            v-if="claimLine?.locationCodeableConcept?.coding?.[0]?.code"
            :code="claimLine?.locationCodeableConcept?.coding?.[0]?.code"
            model="PlaceOfService"
          />
          <span v-else>–</span>
        </td>

        <td class="table-cell">
          <RefDataCode
            v-if="claimLine?.modifier?.[0]?.coding?.[0]?.code"
            :code="claimLine?.modifier?.[0]?.coding?.[0]?.code"
            model="modifier"
          />
          <span v-else>–</span>
        </td>
        <td class="table-cell">
          <RefDataCode
            v-if="claimLine?.modifier?.[1]?.coding?.[0]?.code"
            :code="claimLine?.modifier?.[1]?.coding?.[0]?.code"
            model="modifier"
          />
          <span v-else>–</span>
        </td>
        <td class="table-cell">
          <RefDataCode
            v-if="claimLine?.modifier?.[2]?.coding?.[0]?.code"
            :code="claimLine?.modifier?.[2]?.coding?.[0]?.code"
            model="modifier"
          />
          <span v-else>–</span>
        </td>
        <td class="table-cell">
          <RefDataCode
            v-if="claimLine?.modifier?.[3]?.coding?.[0]?.code"
            :code="claimLine?.modifier?.[3]?.coding?.[0]?.code"
            model="modifier"
          />
          <span v-else>–</span>
        </td>

        <td class="table-cell">
          <RefDataCode
            v-if="claimLine?.diagnosis1?.codeableConcept"
            :code="claimLine?.diagnosis1?.codeableConcept"
            model="Diagnosis"
          />
          <span v-else>–</span>
        </td>
        <td class="table-cell">
          <RefDataCode
            v-if="claimLine?.diagnosisAdditional[0]?.codeableConcept"
            :code="claimLine?.diagnosisAdditional[0]?.codeableConcept"
            model="Diagnosis"
          />
          <span v-else>–</span>
        </td>
        <td class="table-cell">
          <RefDataCode
            v-if="claimLine?.diagnosisAdditional[1]?.codeableConcept"
            :code="claimLine?.diagnosisAdditional[1]?.codeableConcept"
            model="Diagnosis"
          />
          <span v-else>–</span>
        </td>
        <td class="table-cell">
          <RefDataCode
            v-if="claimLine?.diagnosisAdditional[2]?.codeableConcept"
            :code="claimLine?.diagnosisAdditional[2]?.codeableConcept"
            model="Diagnosis"
          />
          <span v-else>–</span>
        </td>
        <td class="table-cell">
          <RefDataCode
            v-if="claimLine?.diagnosisAdditional[3]?.codeableConcept"
            :code="claimLine?.diagnosisAdditional[3]?.codeableConcept"
            model="Diagnosis"
          />
          <span v-else>–</span>
        </td>

        <td class="table-cell">
          {{
            claimLine?.net?.value
              ? formatCents(claimLine?.net?.value * 100)
              : '–'
          }}
        </td>
        <td class="table-cell">
          <span v-if="claimLine?.renderingProvider.identifiers.npi">
            NPI:
            {{ claimLine.renderingProvider.identifiers.npi }}
          </span>
          <span
            v-else-if="claimLine?.renderingProvider.identifiers.ein"
            class="whitespace-nowrap"
          >
            TIN:
            {{ claimLine.renderingProvider.identifiers.ein }}
          </span>
          <span v-else>–</span>
        </td>
        <td class="table-cell">
          {{ formatDisplayDate(claim.patient?.birthDate, 'MM/dd/yyyy') || '–' }}
        </td>
        <td class="table-cell">
          {{ claim?.patient?.gender || '–' }}
        </td>
        <td class="table-cell">
          {{ formatCents(claim.total?.value * 100) }}
        </td>
        <td class="table-cell">
          <p
            v-if="claim.billingProviderIdentifiers?.ein"
            class="whitespace-nowrap"
          >
            TIN: {{ claim.billingProviderIdentifiers.ein }}
          </p>
          <span v-else>–</span>
        </td>
        <td class="table-cell">
          {{ claim.insurance?.[0].preAuthRef?.[0] || '–' }}
        </td>

        <td />
      </tr>
    </template>

    <template #item.line_type="{ item }">
      <span
        class="font-display subtitle-2 rounded-md bg-neutral-100 px-3 py-1 text-center capitalize text-neutral-800"
        :class="{
          'bg-secondary-100 text-secondary-900': item.secondaryHighlight,
        }"
      >
        Influencing
      </span>
    </template>

    <template #item.startDate="{ item }">
      <span>
        {{ formatDisplayDate(item?.startDate, 'MM/dd/yyyy') || '–' }}
      </span>
    </template>
    <template #item.endDate="{ item }">
      <span>
        {{ formatDisplayDate(item?.endDate, 'MM/dd/yyyy') || '–' }}
      </span>
    </template>

    <template #item.procedureCode="{ item }">
      <RefDataCode
        v-if="item?.procedureCode"
        :code="item?.procedureCode"
        model="Procedure"
      />
      <span v-else>–</span>
    </template>

    <template #item.quantityValue="{ item }">
      {{ item?.quantityValue }}
      {{ item?.quantity?.unit }}
    </template>

    <template #item.placeOfService="{ item }">
      <RefDataCode
        v-if="item?.placeOfService"
        :code="item.placeOfService"
        model="PlaceOfService"
      />
      <span v-else>–</span>
    </template>

    <template #item.modifier1="{ item }">
      <RefDataCode
        v-if="item?.modifier1"
        :code="item.modifier1"
        model="modifier"
      />
      <span v-else>–</span>
    </template>

    <template #item.modifier2="{ item }">
      <RefDataCode
        v-if="item?.modifier2"
        :code="item.modifier2"
        model="modifier"
      />
      <span v-else>–</span>
    </template>

    <template #item.modifier3="{ item }">
      <RefDataCode
        v-if="item?.modifier3"
        :code="item.modifier3"
        model="modifier"
      />
      <span v-else>–</span>
    </template>

    <template #item.modifier4="{ item }">
      <RefDataCode
        v-if="item?.modifier4"
        :code="item.modifier4"
        model="modifier"
      />
      <span v-else>–</span>
    </template>

    <template #item.diagnosis1="{ item }">
      <RefDataCode
        v-if="item?.diagnosis1"
        :code="item.diagnosis1"
        model="Diagnosis"
      />
      <span v-else>–</span>
    </template>

    <template #item.diagnosis2="{ item }">
      <RefDataCode
        v-if="item?.diagnosis2"
        :code="item.diagnosis2"
        model="Diagnosis"
      />
      <span v-else>–</span>
    </template>

    <template #item.diagnosis3="{ item }">
      <RefDataCode
        v-if="item?.diagnosis3"
        :code="item.diagnosis3"
        model="Diagnosis"
      />
      <span v-else>–</span>
    </template>

    <template #item.diagnosis4="{ item }">
      <RefDataCode
        v-if="item?.diagnosis4"
        :code="item.diagnosis4"
        model="Diagnosis"
      />
      <span v-else>–</span>
    </template>

    <template #item.diagnosis5="{ item }">
      <RefDataCode
        v-if="item?.diagnosis5"
        :code="item.diagnosis5"
        model="Diagnosis"
      />
      <span v-else>–</span>
    </template>

    <template #item.netValue="{ item }">
      {{ item?.netValue ? formatCents(item?.netValue * 100) : '–' }}
    </template>

    <template #item.renderingProviderId="{ item }">
      <span v-if="item?.renderingProviderNpi">
        NPI:
        {{ item.renderingProviderNpi }}
      </span>
      <span v-else-if="item?.renderingProviderTin" class="whitespace-nowrap">
        TIN:
        {{ item.renderingProviderTin }}
      </span>
      <span v-else>–</span>
    </template>

    <template #item.patientBirthdate="{ item }">
      <span>
        {{ formatDisplayDate(item.patientBirthdate, 'MM/dd/yyyy') || '–' }}
      </span>
    </template>

    <template #item.patientGender="{ item }">
      <span class="uppercase">
        {{ item.patientGender || '–' }}
      </span>
    </template>

    <template #item.totalValue="{ item }">
      <span>{{ formatCents(item.totalValue * 100) }}</span>
    </template>

    <template #item.billingProviderId="{ item }">
      <span v-if="item.billingProviderId" class="whitespace-nowrap">
        TIN: {{ item.billingProviderId }}
      </span>
      <span v-else>–</span>
    </template>
  </DataTable>
</template>

<style scoped>
.table-cell {
  @apply table-numbers-1 p-3 pr-9 uppercase text-neutral-900;
}
</style>
