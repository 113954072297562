import { FilterNames } from '@rialtic/types'

export const insightFilters = {
  active_observation: 'All insights',
  active: 'Active insights only',
  observation: 'Observation insights only',
  no_insights: 'No insights',
}

export const queryParams = {
  editType: 'edit_type',
  policyId: 'policy_id',
  insightMode: 'insight_mode',
  mode: 'mode',
  source: 'source',
  topic: 'topic',
}

export const influencingClaimHeaders = ref([
  {
    key: 'line_type',
    text: '',
    disableSort: true,
  },
  {
    key: 'claimId',
    text: 'Claim ID',
    prose: true,
  },
  {
    align: 'right',
    key: 'lineNumber',
    text: 'Claim Line',
  },
  {
    key: 'startDate',
    text: 'Date of Service (From)',
  },
  {
    key: 'endDate',
    text: 'Date of Service (To)',
  },
  {
    key: 'procedureCode',
    text: 'CPT®/HCPCS',
  },
  {
    key: 'quantityValue',
    text: 'Units',
  },
  {
    key: 'placeOfService',
    text: 'Place of Service',
  },
  {
    key: 'modifier1',
    text: 'Modifier 1',
  },
  {
    key: 'modifier2',
    text: 'Modifier 2',
  },
  {
    key: 'modifier3',
    text: 'Modifier 3',
  },
  {
    key: 'modifier4',
    text: 'Modifier 4',
  },
  {
    key: 'diagnosis1',
    text: 'Diagnosis 1',
  },
  {
    key: 'diagnosis2',
    text: 'Diagnosis 2',
  },
  {
    key: 'diagnosis3',
    text: 'Diagnosis 3',
  },
  {
    key: 'diagnosis4',
    text: 'Diagnosis 4',
  },
  {
    key: 'diagnosis5',
    text: 'Diagnosis 5',
  },
  {
    key: 'netValue',
    text: 'Charges',
  },
  {
    key: 'renderingProviderId',
    text: 'Rendering Provider ID',
  },
  {
    key: 'patientBirthdate',
    text: 'Patient Birthdate',
  },
  {
    key: 'patientGender',
    text: 'Patient Sex',
  },
  {
    key: 'totalValue',
    text: 'Total Charges on Claim',
  },
  {
    key: 'billingProviderId',
    text: 'Billing Provider ID',
  },
  {
    key: 'preAuthRef',
    text: 'Prior Auth #',
  },
])

export const nonFilterableQueries = [
  FilterNames.LOB,
  FilterNames.SORT_BY,
]
